
/* GalleriaAdvancedDemo.css */

.galleria-demo .custom-galleria.fullscreen {
    display: flex;
    flex-direction: column;
}
.galleria-demo .custom-galleria.fullscreen .p-galleria-content {
    flex-grow: 1;
    justify-content: center;
}
.galleria-demo .custom-galleria .p-galleria-content {
    position: relative;
}
.galleria-demo .custom-galleria .p-galleria-thumbnail-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
.galleria-demo .custom-galleria .p-galleria-thumbnail-items-container {
    width: 100%;
}
.galleria-demo .custom-galleria .custom-galleria-footer {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, .9);
    color: #fff;
}
.galleria-demo .custom-galleria .custom-galleria-footer > button {
    background-color: transparent;
    color: #fff;
    border: 0 none;
    border-radius: 0;
    margin: 0.2rem 0;
}
.galleria-demo .custom-galleria .custom-galleria-footer > button.fullscreen-button {
    margin-left: auto;
}
.galleria-demo .custom-galleria .custom-galleria-footer > button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
.galleria-demo .custom-galleria .title-container > span {
    font-size: 0.9rem;
    padding-left: 0.829rem;
}
.galleria-demo .custom-galleria .title-container > span.title {
    font-weight: bold;
}
     