@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';
:root{
    --color1: #264653;
    --color1_1: #111f25;
    --color2: #2A9D8F;
    --color2_1 : #0da2919e;
    --color2_2: #bff5ee;
    --color3: #E9C46A;
    --color4: #F4A261;
    --color5: #E76F51;
}

.body {
    font-family: Roboto !important;
}

.bg-blue-100 {
    background-color: var(--color2_2) !important;
}
.bg-yellow-100 {
    background-color: var(--color3) !important;
}
.text-blue-500 {
    color: var(--color2) !important;
}

.round_ioc_40 {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 45px;
    border-radius: 100%;
    font-size: 20px;
    margin: 5px;
}

.empty_placeholder {
    width: 100%;
    height: 80vh;
    border: 2px dashed var(--gray-400);
    border-radius: 6px;
    color: var(--gray-400);
}

.report_opt_card {
    cursor: pointer;

    &:hover {
        background-color: var(--blue-100);
    }
}

.dounut-middle {
    position: absolute;
    text-align: center;
    top: 50%;
}

.p-accordion-header-text {
    flex: 1;
}

.p-accordion-header-link {
    flex-direction: row-reverse;
}

.card_ioc_grid {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background: var(--color2);
    color: var(--primary-color-text);
}

.p-tabview {
    .p-tabview-panels {
        background: #ffffff00;
        padding: 1rem 0;
    }
}

.p-accordion {
    .p-accordion-content {
        padding: 0.75rem 0rem;
        background: #ffffff00;
    }

    .p-accordion-tab {
        box-shadow: none;
    }

    .p-accordion-header {
        a {
            background-color: var(--blue-50);
        }
    }

    .p-accordion-header:not(.p-highlight):not(.p-disabled) {
        &:hover {
            .p-accordion-header-link {
                background: var(--blue-100);
                border-color: transparent;
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }
}

.wi-ioc {
    svg {
        width: 50px;
        height: 50px;
    }
}

.antanna-image {
    max-width: 100%;
    max-height: 40px;
}

.green-bg {
    background-color: #628c3f !important;
    color: white !important;
}

.green-text {
    color: #628c3f !important;
}

.layout-menu-button {
    background-color: #0c6291 !important;
}

.light-green-bg {
    background-color: #b0cb99 !important;
}

.app-breadcrumb {
    ul {
        li {
            position: relative;
            padding-right: 25px;
            cursor: pointer;

            &::after {
                position: absolute;
                top: 2px;
                right: 10px;
                content: "";
                width: 10px;
                height: 10px;
                background: transparent;
                border-top: 2px solid #000000;
                border-right: 2px solid #000000;
                transform: rotate(45deg);
            }
        }
    }
}

.icon_wrapper {
    padding: 10px;
    width: 80px;
    height: 80px;
    background: var(--color2);
    color: var(--surface-300);
    border-radius: 4px;

    svg {
        width: 60px;
        height: 60px;
    }
}

.query_condition {
    width: 100%;

    h6 {
        border-bottom: 1px solid var(--surface-300);
        cursor: pointer;

        &:hover {
            background: var(--blue-100);
        }
    }
}


.blue-bg {
    background-color: #0c6291 !important;
    color: white !important;
}

.blue-text {
    color: #0c6291 !important;
}

.title-font-size {
    font-size: "3rem" !important;
}

.light-blue-bg {
    background-color: #52addf !important;
    color: white !important;
}

.portfolio-widgets {
    background-color: #ffffff;
    padding: 1rem;
    margin-bottom: 0.5rem;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    min-height: 45vh;
}

.tenancyTbl {
    .p-datatable {
        .p-column-header-content {
            justify-content: center;
        }
    }
}

.layout-menu-light {
    .menu-wrapper {
        .layout-menu-container {
            .layout-menu {
                li {
                    a {
                        font-size: 18px;

                        // color: var(--text-color);
                        i {
                            font-size: 18px;
                        }
                    }

                }
            }
        }
    }
}

.layout-topbar-blue {
    .layout-topbar {
        .layout-topbar-left {
            background-color: #ffffff;
        }

        background-color: #ffffff;
        color: black !important;

    }
}

.layout-topbar-blue .layout-topbar button {
    color: black !important;
}

.text-blue-600 {
    color: var(--color1) !important;
    font-size: 1.25rem;
}


.errorstyle {
    margin: auto;
    padding: 10px;
    color: red;
    font-size: 14px;
}

.login-button {
    background: #0c6291 !important;
}

.p-tabview-title {
    line-height: 1;
    white-space: nowrap;
    font-size: 20px;
}

.p-breadcrumb {
    ul {
        li {
            .p-menuitem-link {
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
}

.card_info {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.login-img {
    // background: url("../public/assets/layout/images/drone-telecom-inspection.png");
    background-color: #0c6291;
    background-size: cover;
}

.query_grid {
    .p-divider.p-divider-horizontal {
        margin: 0;
    }
}

.fault-widgets {
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 4px;
    min-height: 40vh;
}

.card {
    min-height: 112px;
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu li a.router-link-active,
.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu li button.router-link-active {
    background-color: #0c6291;
    color: #ffffff;
    font-weight: bold;
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu li a.router-link-active i,
.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu li button.router-link-active i {
    color: #ffffff;
}


.gauge {
    position: relative;
    border-radius: 50%/100% 100% 0 0;
    background-color: var(--color2);
    overflow: hidden;
}

.gauge:before {
    content: "";
    display: block;
    padding-top: 50%;
    /* ratio of 2:1*/
}

.gauge .mask {
    position: absolute;
    left: 20%;
    right: 20%;
    bottom: 0;
    top: 40%;
    background-color: #fff;
    border-radius: 50%/100% 100% 0 0;
}

.gauge .percentage {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: 0;
    right: -1px;
    background-color: #e9ecef;
    color: var(--color2);
    transform-origin: bottom center;
    transition-duration: 600;
}

.gauge .value {
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 100%;
    text-align: center;
}

.gauge .min {
    position: absolute;
    bottom: 0;
    left: 5%;
}

.gauge .max {
    position: absolute;
    bottom: 0;
    right: 5%;
}
.clickAble {
    cursor: pointer;
    &:hover {
        background: rgba($color: #000000, $alpha: 0.3);
    }
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler {
    color: white;
}

.color1-bg {
    background-color: var(--color1) !important;
    color: white !important;
}

.color2-bg {
    background-color: var(--color2) !important;
    color: white !important;
}

.color3-bg {
    background-color: var(--color3) !important;
    color: white !important;
}
.color3_1-bg{
    background-color: var(--color3_1) !important;
    color: white !important;
}
.color5-bg{
    background-color: var(--color5) !important;
    color: white !important;
}

.color1_1-bg{
    background-color: var(--color1_1) !important;
    color: white !important;
}

.responsive-dialog {
    width: 35vw;
}

code[class*="language-"],
pre[class*="language-"] {
	color: white !important;
}
@media (max-width: 900px) {
    .layout-wrapper .layout-main .layout-content {
        padding: 1rem;
    }

    .p-breadcrumb ul {
        width: 90%;
        flex-wrap: wrap;
    }
    .responsive-dialog {
        width: 80%;
    }
}